/**
 * ==================================================================
 * Header Component
 * ==================================================================
 *
 * @author kode.id Engineering Team - https://github.com/kodeid
 */

/** @jsx jsx */
import { jsx } from '@emotion/core';

import kodeLogo from '../../../assets/images/logo/kode-logo-dark.png';
import { fetchBrowserCookies } from '../../../helpers';
import {
  headerStyles,
  imageWrapperStyles,
  imageStyles,
  userNameStyles,
} from './Header.styles';

const Header = () => {
  const user = fetchBrowserCookies();

  return (
    <header css={headerStyles}>
      <a href="https://kode.id" css={imageWrapperStyles}>
        <img src={kodeLogo} css={imageStyles} alt="kode-logo" />
      </a>
      <span css={userNameStyles}>{`hai, ${user.firstName}!`}</span>
    </header>
  );
};

export default Header;
