/** @jsx jsx */
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

import { buttonStyles } from './Button.styles';

const Button = ({ children, styles, ...rest }) => (
  <button type="submit" css={[buttonStyles, styles]} {...rest}>
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.string.isRequired,
  styles: PropTypes.shape({}),
};

Button.defaultProps = {
  styles: css``,
};

export default Button;
