import { css } from '@emotion/core';

import { colors } from '../../../configs';

export const selectStyles = css`
  width: 100%;
  height: 50px;
  margin: 0 0 15px 0;
  padding: 0 60px;
  border: 1px solid ${colors.KODE_LIGHT_GREY};
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 16px;
  color: ${colors.KODE_CHARCOAL};
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
`;
