/** @jsx jsx */
import PropTypes from 'prop-types';
import { useState } from 'react';
import { css, jsx } from '@emotion/core';

import { Icon } from '../../commons';
import {
  inputContainerStyles,
  iconStyles,
  inputStyles,
  showAndHideButtonStyles,
} from './Input.styles';

const Input = ({
  type,
  containerStyles,
  styles,
  name,
  value,
  onChange,
  placeholder,
  iconName,
  ...rest
}) => {
  const [isShowPassword, setPasswordStatus] = useState(false);

  const handleShowHidePasswordChange = () => {
    setPasswordStatus((prevState) => !prevState);
  };

  return (
    <div css={[inputContainerStyles, containerStyles]}>
      <Icon type="DEFAULT" styles={iconStyles} name={iconName} />
      <input
        type={isShowPassword ? 'text' : type}
        css={[inputStyles, styles]}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      {type === 'password' && (
        <button
          type="button"
          tabIndex={-1}
          css={showAndHideButtonStyles}
          onClick={handleShowHidePasswordChange}
        >
          <Icon type="DEFAULT" name={isShowPassword ? 'eye-slash' : 'eye'} />
        </button>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  containerStyles: PropTypes.shape({}),
  styles: PropTypes.shape({}),
  iconName: PropTypes.string.isRequired,
};

Input.defaultProps = {
  containerStyles: css``,
  styles: css``,
};

export default Input;
