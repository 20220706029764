import { css } from '@emotion/core';

import { colors } from '../../../configs';

export const formStyles = css`
  margin: 40px 0 0 0;
`;

export const labelStyles = css`
  display: block;
  margin: 30px 0 0 0;
  line-height: 1.5;
  font-size: 14px;
  color: ${colors.KODE_DARK_GREY};

  &:first-of-type {
    margin: 0;
  }

  @media only screen and (min-width: 1200px) {
    line-height: 1;
  }
`;

export const inputStyles = css`
  margin: 15px 0 0 0;
`;

export const submitButtonStyles = css`
  margin: 30px 0 0 0;
`;
