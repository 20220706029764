/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import { iconStyles } from './Icon.styles';

const TYPES = {
  DEFAULT: 'DEFAULT',
  REGULAR: 'REGULAR',
  LIGHT: 'LIGHT',
  BRAND: 'BRAND',
};

const Icon = ({
  type,
  styles,
  name,
}) => {
  let faIconType;

  switch (type) {
    case TYPES.REGULAR:
      faIconType = 'far';
      break;
    case TYPES.LIGHT:
      faIconType = 'fal';
      break;
    case TYPES.BRAND:
      faIconType = 'fab';
      break;
    case TYPES.DEFAULT:
    default:
      faIconType = 'fa';
  }

  return <i className={`${faIconType} fa-${name}`} css={[iconStyles, styles]} />;
};

Icon.propTypes = {
  type: PropTypes.string,
  styles: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  type: '',
  styles: css``,
};

export default Icon;
