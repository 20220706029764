import { css } from '@emotion/core';

import { colors } from '../../../configs';

export const headerStyles = css`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 17px 20px;
  background-color: ${colors.WHITE};
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 5000;
`;

export const imageWrapperStyles = css`
  width: 100px;
  height: auto;
  outline: none;
  cursor: pointer;

  @media only screen and (min-width: 1280px) {
    width: 75px;
  }
`;

export const imageStyles = css`
  width: 100%;
  height: 100%;
`;

export const userNameStyles = css`
  text-transform: capitalize;
`;
