/* eslint-disable import/no-cycle */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';

import Header from './Header/Header';
import {
  layoutStyles,
  snackbarStyles,
  messageStyles,
  contentStyles,
} from './Layout.styles';
import { Text } from '../commons';

const Layout = ({ message, children }) => (
  <div css={layoutStyles}>
    {message && (
      <div css={snackbarStyles}>
        <Text styles={messageStyles} fontSize={16}>
          {message}
        </Text>
      </div>
    )}
    <Header />
    <div css={contentStyles(message)}>{children}</div>
  </div>
);

Layout.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  message: '',
};

export default Layout;
