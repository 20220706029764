import { css } from '@emotion/core';

import { colors } from '../../../configs';

export const pageNotFoundContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 95vh;
`;

export const imageStyles = css`
  width: 100%;
  max-width: 400px;
  height: auto;
`;

export const messageStyles = css`
  margin: 30px 0 0 0;
  font-weight: 700;
  color: ${colors.KODE_CHARCOAL};
`;
