import { /* Certificate, */ Profile } from '../pages';

export default [
  {
    isExact: true,
    path: '/',
    component: Profile,
  },
  // {
  //   isExact: true,
  //   path: '/certificate',
  //   component: Certificate,
  // },
];
