/** @jsx jsx */
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

import { selectStyles } from './SelectBox.styles';

const SelectBox = ({
  styles,
  options,
  name,
  value,
  onChange,
  placeholder,
}) => (
  <select
    css={[selectStyles, styles]}
    name={name}
    value={value}
    onChange={onChange}
  >
    <option value="">{`- ${placeholder} -`}</option>
    {options.map((option) => (
      <option key={option.value} value={option.value}>{option.label}</option>
    ))}
  </select>
);

SelectBox.propTypes = {
  styles: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

SelectBox.defaultProps = {
  styles: css``,
};

export default SelectBox;
