/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';

import Layout from '../../components/layout/Layout';
import { ShortText, Text } from '../../components/commons';
import { ProfileForm } from '../../components/pages';
import { checkEmail, updateUserName } from '../../services';
import { fetchBrowserCookies } from '../../helpers';
import {
  bannerStyles,
  bannerTextStyles,
  bodyStyles,
  contentStyles,
  tabContainerStyles,
  linkStyles,
  disclaimerContainerStyles,
  disclaimerStyles,
} from './Profile.styles';

const Profile = () => {
  const [message, setMessage] = useState('');
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [isFormSubmitting, setFormSubmittingStatus] = useState(false);

  const handleChange = (e) => {
    e.persist();
    setUserData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const resetFullNameCookie = ({ firstName, lastName }) => {
    document.cookie = `kode_user_first_name=${encodeURI(firstName)}; domain=${
      process.env.REACT_APP_DOMAIN
    }`;
    document.cookie = `kode_user_last_name=${encodeURI(lastName)}; domain=${
      process.env.REACT_APP_DOMAIN
    }`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmittingStatus(true);

    const { email, firstName, lastName } = userData;

    await checkEmail({ email })
      .then(() => {
        updateUserName({ userData })
          .then(() => {
            setMessage('sukses mengubah data.');
            setUserData({
              email,
              firstName,
              lastName,
            });
            resetFullNameCookie({ firstName, lastName });
          })
          .catch((err) => {
            if (err.response.data.message === 'User could not be updated') {
              setMessage('tidak dapat mengubah data.');
            }
          });
      })
      .catch(() => {
        setMessage(`email ${email} tidak terdaftar.`);
      });
    setFormSubmittingStatus(false);
  };

  useEffect(() => {
    const currentUser = fetchBrowserCookies();
    const { email } = currentUser;

    const fetchUserData = async () => {
      await checkEmail({ email })
        .then((res) => {
          const { data } = res.data;

          setUserData({
            email: data.email,
            firstName: data.first_name,
            lastName: data.last_name,
          });
        })
        .catch(() => {
          setMessage(`email ${email} tidak terdaftar.`);
        });
    };

    fetchUserData();
  }, []);

  return (
    <Layout message={message}>
      <div css={bannerStyles}>
        <ShortText
          styles={bannerTextStyles}
          textTransform="capitalize"
          fontSize={20}
        >
          my account
        </ShortText>
      </div>
      <div css={bodyStyles}>
        <div css={contentStyles}>
          <div css={tabContainerStyles}>
            <NavLink
              css={linkStyles}
              to="/"
              activeClassName="active-link"
              exact
            >
              my profile
            </NavLink>
            {/* <NavLink
              css={linkStyles}
              to="/certificate"
              activeClassName="active-link"
              exact
            >
              my certificate
            </NavLink> */}
          </div>
          <div css={disclaimerContainerStyles}>
            <Text styles={disclaimerStyles} fontSize={12}>
              Nama akan tertera pada sertifikat. Perubahan nama tidak akan mengubah sertifikat yang telah
              dikirimkan.
            </Text>
          </div>
          <ProfileForm
            handleSubmit={handleSubmit}
            data={userData}
            onChange={handleChange}
            isFormSubmitting={isFormSubmitting}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
