import { css } from '@emotion/core';

import { colors } from '../../../configs';

export const containerStyles = css`
  width: 100%;
  max-width: 350px;
  padding: 20px 40px;
  background-color: ${colors.WHITE};
  border-left: 5px solid ${colors.KODE_TOPAZ};
  border-radius: 0 5px 5px 0;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 30px 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.3);
  }

  @media only screen and (min-width: 1200px) {
    margin: 0 30px 30px 0;

    &:nth-of-type(3n) {
      margin: 0 0 30px 0;
    }
  }
`;

export const labelStyles = css`
  color: ${colors.KODE_DARK_GREY};
`;

export const medalIconStyles = css`
  margin: 0 10px 0 0;
`;

export const courseTitleStyles = css`
  height: 45px;
  margin: 15px 0 0 0;
  line-height: 1.85;
  font-weight: 700;
  color: ${colors.KODE_CHARCOAL};
`;

export const thumbnailFooterStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 0 0;
`;

export const issuedDateStyles = css`
  color: ${colors.KODE_CHARCOAL};
`;

export const downloadIconStyles = css`
  font-size: 20px;
  color: ${colors.KODE_TOPAZ};
`;
