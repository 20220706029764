import { css } from '@emotion/core';

import { colors } from '../../../configs';

export const inputContainerStyles = css`
  position: relative;
  width: 100%;
`;

export const iconStyles = css`
  position: absolute;
  top: 50%;
  left: 20px;
  margin: -10px 0 0 0;
`;

export const inputStyles = css`
  width: 100%;
  height: 50px;
  padding: 0 60px;
  border: 1px solid ${colors.KODE_LIGHT_GREY};
  border-radius: 10px;
  font-size: 16px;
  color: ${colors.KODE_CHARCOAL};
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::placeholder {
    text-transform: capitalize;
    font-size: 12px;
    color: ${colors.KODE_DARK_GREY};
  }

  &:focus {
    border-color: ${colors.KODE_TOPAZ};
  }

  &:read-only {
    background-color: ${colors.KODE_LIGHT_GREY};
    color: ${colors.KODE_DARK_GREY};
    cursor: not-allowed;

    &:focus {
      border-color: ${colors.KODE_LIGHT_GREY};
    }
  }
`;

export const showAndHideButtonStyles = css`
  position: absolute;
  top: 50%;
  right: 20px;
  margin: -10px 0 0 0;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`;
