import { css } from '@emotion/core';

import { colors } from '../../configs';

export const layoutStyles = css`
  width: 100%;
  min-height: 100vh;
`;

export const snackbarStyles = css`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 5px 30px;
  background-color: ${colors.KODE_TOPAZ};
  z-index: 9999;
`;

export const messageStyles = css`
  text-align: center;
  color: ${colors.WHITE};
`;

export const contentStyles = (message) => css`
  margin: ${message ? '130' : '80'}px 0 0 0;
`;
