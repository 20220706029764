import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Global } from '@emotion/core';

import routeConfigs from './routes/route.config';
import { ShortText, PageNotFound } from './components/commons';
import { fetchBrowserCookies } from './helpers';
import { globalStyles } from './configs';

const App = () => {
  const [isRedirect, setRedirectStatus] = useState(true);

  const redirectContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100vh',
    fontFamily: 'Open Sans, sans-serif',
  };

  useEffect(() => {
    const currentUser = fetchBrowserCookies();
    const { id } = currentUser;

    if (!id) {
      window.location.href = 'https://auth.kode.id/signin';
    } else {
      setRedirectStatus(false);
    }
  }, []);

  return isRedirect ? (
    <div style={redirectContainerStyles}>
      <ShortText textTransform="capitalize" fontSize={16}>
        redirecting...
      </ShortText>
    </div>
  ) : (
    <>
      <Helmet>
        <title>KODE - Semua Bisa Belajar</title>
      </Helmet>
      <Global styles={globalStyles} />
      <Switch>
        {routeConfigs.map((route) => (
          <Route
            key={route.path}
            exact={route.isExact}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default App;
