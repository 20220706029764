/* eslint-disable import/no-cycle */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import ShortText from '../ShortText/ShortText';

import Layout from '../../layout/Layout';
import pageNotFoundImage from '../../../assets/images/illustrations/page-not-found.png';
import {
  pageNotFoundContainerStyles,
  imageStyles,
  messageStyles,
} from './PageNotFound.styles';

const PageNotFound = () => (
  <Layout>
    <div css={pageNotFoundContainerStyles}>
      <img src={pageNotFoundImage} css={imageStyles} alt="404-page-not-found" />
      <ShortText
        styles={messageStyles}
        textTransform="capitalize"
        fontSize={24}
      >
        404 page not found
      </ShortText>
    </div>
  </Layout>
);

export default PageNotFound;
