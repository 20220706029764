/** @jsx jsx */
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';

import colors from '../../../configs/colors';
import { shortTextStyles } from './ShortText.styles';

const ShortText = ({
  children,
  styles,
  textTransform,
  fontSize,
  color,
}) => (
  <span css={[shortTextStyles({ textTransform, fontSize, color }), styles]}>
    {children}
  </span>
);

ShortText.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.shape({}),
  textTransform: PropTypes.oneOf([
    'none',
    'lowercase',
    'capitalize',
    'uppercase',
  ]),
  fontSize: PropTypes.number.isRequired,
  color: PropTypes.string,
};

ShortText.defaultProps = {
  styles: css``,
  textTransform: 'none',
  color: colors.MIDNIGHT_BLUE,
};

export default ShortText;
