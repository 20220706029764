import { css } from '@emotion/core';

import { colors } from '../../configs';

export const bannerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: ${colors.LIGHT_GREY_2};
`;

export const bannerTextStyles = css`
  font-weight: 700;
  color: ${colors.KODE_CHARCOAL};
`;

export const bodyStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 15px;

  @media only screen and (min-width: 1200px) {
    padding: 40px 0;
  }
`;

export const contentStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const tabContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const linkStyles = css`
  position: relative;
  margin: 0 40px 0 0;
  padding: 0 0 16px 0;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  color: ${colors.KODE_CHARCOAL};

  &:last-of-type {
    margin: 0;
  }

  &.active-link {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 3px;
      background-color: ${colors.KODE_TOPAZ};
    }
  }
`;

export const loaderContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 250px;
  margin: 40px 0 0 0;
`;

export const certificateThumbnailContainerStyles = (
  certificateDataLength,
) => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${certificateDataLength === 0 ? 'center' : 'flex-start'};
  width: 100%;
  margin: 40px 0 0 0;

  @media only screen and (min-width: 1200px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: ${certificateDataLength === 0 ? 'center' : 'flex-start'};
    align-items: flex-start;
    width: 1110px;
  }
`;

export const emptyStateContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const emptyStateImageStyles = css`
  width: 100%;
  max-width: 630px;
  height: auto;
`;

export const emptyStateMessage = css`
  margin: 10px 0 0 0;
  text-align: center;
  line-height: 1.5;
  font-weight: 700;
  color: ${colors.KODE_CHARCOAL};

  &:first-of-type {
    margin: 30px 0 0 0;
  }
`;
