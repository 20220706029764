import { css } from '@emotion/core';

import { colors } from '../../configs';

export const bannerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: ${colors.LIGHT_GREY_2};
`;

export const bannerTextStyles = css`
  font-weight: 700;
  color: ${colors.KODE_CHARCOAL};
`;

export const bodyStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 30px;

  @media only screen and (min-width: 1200px) {
    padding: 40px 0;
  }
`;

export const contentStyles = css`
  width: 350px;
  height: auto;
`;

export const tabContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const linkStyles = css`
  position: relative;
  margin: 0 40px 0 0;
  padding: 0 0 16px 0;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 14px;
  color: ${colors.KODE_CHARCOAL};

  &:last-of-type {
    margin: 0;
  }

  &.active-link {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 3px;
      background-color: ${colors.KODE_TOPAZ};
    }
  }
`;

export const disclaimerContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 0 0;
  padding: 15px;
  border: 1px solid ${colors.KODE_TOPAZ};
  border-radius: 10px;
  background-color: ${colors.KODE_LIGHT_TOPAZ};
`;

export const disclaimerStyles = css`
  text-align: center;
  line-height: 1.85;
  font-weight: 700;
  color: ${colors.KODE_TOPAZ};
`;
