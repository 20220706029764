/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from '@emotion/core';

import { Input, Button } from '../../forms';
import {
  formStyles,
  labelStyles,
  inputStyles,
  submitButtonStyles,
} from './Form.styles';

const Form = ({
  handleSubmit,
  data,
  onChange,
  isFormSubmitting,
}) => {
  const { firstName, lastName, email } = data;

  return (
    <form css={formStyles} onSubmit={handleSubmit}>
      <label css={labelStyles}>
        Nama Depan
        <Input
          type="text"
          containerStyles={inputStyles}
          iconName="user"
          name="firstName"
          value={firstName}
          onChange={onChange}
          placeholder="nama depan"
        />
      </label>
      <label css={labelStyles}>
        Nama Belakang
        <Input
          type="text"
          containerStyles={inputStyles}
          iconName="user"
          name="lastName"
          value={lastName}
          onChange={onChange}
          placeholder="nama belakang"
        />
      </label>
      <label css={labelStyles}>
        Alamat E-mail
        <Input
          type="email"
          containerStyles={inputStyles}
          iconName="mail"
          name="email"
          value={email}
          onChange={onChange}
          placeholder="email"
          readOnly
        />
      </label>
      <Button type="submit" styles={submitButtonStyles}>
        {isFormSubmitting ? 'menunggu...' : 'ubah data'}
      </Button>
    </form>
  );
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
};

export default Form;
