import { css } from '@emotion/core';

import { colors } from '../../../configs';

export const buttonStyles = css`
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border-radius: 50px;
  border: 1px solid ${colors.KODE_RED};
  background-color: ${colors.KODE_RED};
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  color: ${colors.WHITE};
  outline: none;
  transition: opacity 0.2s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
