import { css } from '@emotion/core';

export const textStyles = ({ textTransform, fontSize, color }) => css`
  text-transform: ${textTransform};
  font-size: ${fontSize}px;
  color: ${color};

  &:first-letter {
    text-transform: uppercase;
  }
`;
