import axios from 'axios';

import { fetchBrowserCookies } from '../helpers';

const updateUserName = async ({ userData }) => {
  const currentUser = fetchBrowserCookies();

  const res = await axios({
    headers: {
      Authorization: `Bearer ${currentUser.accessToken}`,
    },
    url: `${process.env.REACT_APP_KODE_API}/users/${userData.email}`,
    method: 'put',
    data: {
      first_name: userData.firstName,
      last_name: userData.lastName || '-',
    },
  });

  return res;
};

export default updateUserName;
